'use client';

import Image from 'next/image';
import { useState } from 'react';
import CalendlyDialog from './calendly-dialog';
import Dialog from './dialog';

export default function ThisWeeksZoom() {
  const [calendlyIsOpen, setCalendlyIsOpen] = useState(false);
  const [learnMoreIsOpen, setLearnMoreIsOpen] = useState(false);

  return (
    <>
      <div className='col-span-6 mt-8 border-l-0 border-gray-200 text-center lg:mt-0 lg:border-l lg:px-4'>
        <div className='font-bold'>
          This Week&apos;s Zoom
        </div>
        <ul className='flex flex-col items-center justify-center gap-2'>
          <li>
            <div>
              <div
                className='cursor-pointer text-sm italic hover:underline'
                onClick={() => setCalendlyIsOpen(true)}
              >
                For Sermons.com training, schedule a Zoom event
              </div>
              <div
                className='cursor-pointer font-serif text-sm italic text-gray-400'
                onClick={() => setLearnMoreIsOpen(true)}
              >
                learn more
              </div>
            </div>
          </li>
          <li className='-my-2'>
            <div>
              <Image
                className='cursor-pointer transition-all ease-in-out hover:[transform:scale(1.125)]'
                src='/img/zoom-icon.png'
                width={64}
                height={64}
                alt='Zoom'
                onClick={() => setCalendlyIsOpen(true)}
              />
            </div>
          </li>
          <div className='text-sm'>
            Members and Non-Members Welcome!
          </div>
        </ul>
      </div>
      <CalendlyDialog calendlyIsOpen={calendlyIsOpen} setCalendlyIsOpen={setCalendlyIsOpen} />
      <Dialog
        className='grid !max-w-5xl grid-cols-1 gap-4 overflow-hidden rounded bg-white p-4'
        isOpen={learnMoreIsOpen}
        onBackdropClick={() => setLearnMoreIsOpen(false)}
      >
        <h3 className='text-2xl'>Join A Sermons.com Zoom Training Call</h3>
        <p>Join other clergy in a Zoom call to learn how to get the most out of Sermons.com resources. Leaders will conduct open forums and guide you through the site. Anyone at any time can speak up and ask questions or add insight on the use of Sermons.com. Events happen every week Mon & Wed starting at 10am and 11am eastern. Members and non-members welcome! Questions welcomed by all! You&apos;ll receive a Zoom link by email after you scedule your participation.</p>
        <p>
          <span
            className='cursor-pointer text-sm italic hover:underline'
            onClick={() => {
              setCalendlyIsOpen(true);
              setLearnMoreIsOpen(false);
            }}
          >
            Schedule the Zoom event
          </span>
        </p>
      </Dialog>
    </>
  );
}
